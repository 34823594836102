(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

// import 'bootstrap/js/dist/alert'
// import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tooltip'

// import '../../../modules/modules'
// import '../../../templates/templates'
jQuery(document).ready(function ($) {

  // round up number fields if user inputs decimal
  $(".gform_body input[type=number]").on('change', function () {
    var curr_val = $(this).val();
    var decimal_present = curr_val.indexOf('.');
    // if there is a decimal present
    if (decimal_present != '-1') {
      $(this).val(Math.round(curr_val));
    }
  });
});

},{}]},{},[1]);
